<template>
  <div class>
    <div class="card">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0 text-uppercase">Payout requests</h3>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <tabs>
          <tab-pane :title="'Pending (' + pendingPayouts.length + ')'">
            <div>
              <div class="table-responsive" v-if="pendingPayouts.length > 0">
                <base-table thead-classes="thead-light" :data="pendingPayouts">
                  <template slot="columns">
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Method</th>
                    <th>Date</th>
                    <th>Account</th>
                    <th></th>
                  </template>

                  <template slot-scope="{row}">
                    <td>{{row.created_at}}</td>
                    <td>{{row.amount | currency}}</td>
                    <td>
                      <span class="badge badge-info">{{row.method}}</span>
                    </td>
                    <td>{{row.cdt}}</td>
                    <td>
                      <div>{{row.account_number}}</div>
                      <div>
                        <small>{{row.account_remarks}}</small>
                      </div>
                    </td>
                    <td>
                      <span v-if="row.method=='withdraw'">
                        <span
                          v-if="row.status=='pending'"
                          class="badge badge-primary pointer mr-1"
                          @click="payoutStatusChange(row, 'processing')"
                        >processing</span>
                        <span
                          v-if="row.status=='processing'"
                          class="badge badge-danger pointer mr-1"
                          @click="payoutStatusChange(row, 'pending')"
                        >cancel</span>
                        <span
                          v-if="row.status=='processing'"
                          class="badge badge-primary pointer mr-1"
                          @click="payoutApprove(row)"
                        >approve</span>
                      </span>
                      <span
                        v-if="row.method=='reinvest'"
                        class="badge badge-primary pointer"
                        @click="reinvestApprove(row)"
                      >approve</span>
                    </td>
                  </template>
                </base-table>
              </div>
              <div v-else>No records</div>
            </div>
          </tab-pane>
          <tab-pane :title="'Approved (' + approvedPayouts.length + ')'">
            <div>
              <div class="table-responsive" v-if="approvedPayouts.length > 0">
                <base-table thead-classes="thead-light" :data="approvedPayouts">
                  <template slot="columns">
                    <th>Amount</th>
                    <th>Method</th>
                    <th>Date</th>
                    <th>Account</th>
                  </template>

                  <template slot-scope="{row}">
                    <td>{{row.amount}}</td>
                    <td>
                      <span class="badge badge-info">{{row.method}}</span>
                    </td>
                    <td>{{row.cdt}}</td>
                    <td>
                      <div>{{row.account_number}}</div>
                      <div>
                        <small>{{row.account_remarks}}</small>
                      </div>
                      <div v-if="row.method == 'withdraw'">
                        <a target="_blank" :href="row.rcptfile" class="badge badge-info">View slip</a>
                      </div>
                    </td>
                  </template>
                </base-table>
              </div>
              <div v-else>No records</div>
            </div>
          </tab-pane>
          <tab-pane :title="'Completed (' + completedPayouts.length + ')'">
            <div>
              <div class="table-responsive" v-if="completedPayouts.length > 0">
                <base-table thead-classes="thead-light" :data="completedPayouts">
                  <template slot="columns">
                    <th>Amount</th>
                    <th>Method</th>
                    <th>Date</th>
                    <th>Account</th>
                    <th></th>
                  </template>

                  <template slot-scope="{row}">
                    <td>{{row.amount | currency}}</td>
                    <td>
                      <span class="badge badge-info">{{row.method}}</span>
                    </td>
                    <td>{{row.cdt}}</td>
                    <td>
                      <div>{{row.account_number}}</div>
                      <div>
                        <small>{{row.account_remarks}}</small>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.method == 'withdraw'">
                        <a target="_blank" :href="row.rcptfile" class="badge badge-info">View slip</a>
                      </div>
                    </td>
                  </template>
                </base-table>
              </div>
              <div v-else>No records</div>
            </div>
          </tab-pane>
        </tabs>
      </div>
    </div>

    <modal :show.sync="modals.showReinvestModal" modal-classes="modal-dialog-centered modal-lg">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Assign Project</h5>
      </template>
      <div>
        <div class="table-responsive">
          <reinvest-projects-list
            @close="modals.showReinvestModal = false; init()"
            :payout="selectedRow"
          ></reinvest-projects-list>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.showReinvestModal = false">Close</base-button>
      </template>
    </modal>

    <modal :show.sync="modals.showRcptModal" modal-classes="modal-dialog-centered modal-lg">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Attach payment receipt</h5>
      </template>
      <div>
        <form @submit.prevent="submit">
          <input
            type="text"
            class="form-control"
            placeholder="Ref"
            required
            v-model="rcptForm.payment_ref"
          />

          <div class="my-4">
            <label>
              File
              <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" />
            </label>
          </div>

          <button class="btn btn-primary" type="submit" style="margin-top:10px">Submit</button>
        </form>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.showRcptModal = false">Close</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import ReinvestProjectsList from "./ReinvestProjectsList.vue";

export default {
  props: {
    user: Object,
  },
  components: {
    ReinvestProjectsList,
  },
  data() {
    return {
      items: [],
      activeTab: 0,
      modals: {
        showRcptModal: false,
        showReinvestModal: false,
      },
      selectedRow: {},
      rcptForm: {
        payment_ref: "",
        rcpt_file: {},
        status: "approved",
      },
    };
  },
  computed: {
    pendingPayouts() {
      let lst = [];
      this.items.forEach((el) => {
        if (["pending", "processing"].includes(el.status)) {
          lst.push(el);
        }
      });
      return lst;
    },
    approvedPayouts() {
      let lst = [];
      this.items.forEach((el) => {
        if (el.status == "approved") {
          lst.push(el);
        }
      });
      return lst;
    },
    completedPayouts() {
      let lst = [];
      this.items.forEach((el) => {
        if (el.status == "completed") {
          lst.push(el);
        }
      });
      return lst;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      api.payout.pendingUserPayouts(this.user.uuid).then((res) => {
        this.items = res.data.data;
      });
    },
    payoutApprove(row) {
      this.selectedRow = row;
      this.modals.showRcptModal = true;
    },
    payoutStatusChange(row, status) {
      if (confirm("Confirm action?")) {
        api.payout.payoutStatusChange(row.uuid, status).then(() => {
          this.$emit("refresh");
          this.init();
        });
      }
    },
    reinvestApprove(row) {
      this.selectedRow = row;
      // console.log(row);
      this.modals.showReinvestModal = true;
    },
    handleFileUpload() {
      this.rcptForm.rcpt_file = this.$refs.file.files[0];
    },
    submit(e) {
      e.preventDefault();
      // console.log(this.selectedRow.uuid);
      let formData = new FormData();
      formData.append("payment_ref", this.rcptForm.payment_ref);
      formData.append("file", this.rcptForm.rcpt_file);
      formData.append("status", this.rcptForm.status);
      api.payout
        .uploadPaymentRcpt(this.selectedRow.uuid, formData)
        .then((res) => {
          this.modals.showRcptModal = false;
          this.$emit("refresh");
          this.init();
        });
    },
  },
};
</script>