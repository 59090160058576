<template>
  <div v-if="dataloaded">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Total"
            type="gradient-red"
            :sub-title="`${rows.top.length}`"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Total investment</th>
                  <th>Monthly</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows.top" :key="index">
                  <td width="2%">{{ index+1 }}</td>
                  <td class="text-uppercase">
                    <router-link :to="'/investor/' + row.user_uuid">{{row.name}}</router-link>
                  </td>
                  <td>{{row.sum | currency}}</td>
                  <td>{{row.monthly | currency}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th>Total</th>
                  <th></th>
                  <th>{{ rows.monthlySum | currency }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="mt-2">
            <small>Monthly calculated for a 30-day period. Actual monthly payout figure may vary depending on the number of days in a given month.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataloaded: false,
      rows: []
    };
  },

  mounted() {
    api.invest.top().then(res => {
      this.rows = res.data.data;
      this.dataloaded = true;
    });
  }
};
</script>