<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <button @click="init()" class="mb-3 btn btn-primary">Reload</button>
      <form @submit.prevent="init">
        <div class="row mt-5">
          <div class="col-5">
            <select v-model="searchForm.year" class="form-control">
              <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </select>
          </div>
          <div class="col-5">
            <select v-model="searchForm.month" class="form-control">
              <option v-for="month in monthNames" :key="month" :value="month">{{ month }}</option>
            </select>
          </div>
          <div class="col-2">
            <button type="submit" class="btn btn-primary">Go</button>
          </div>
        </div>
      </form>
    </base-header>
    <div>
      <div class="card" v-if="dataloaded">
        <div class="card-body">
          <!-- <pre>
            {{ rowx }}
          </pre>-->
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Day</th>
                  <th class="bg-info text-white text-right">OB</th>
                  <th
                    class="text-right"
                    :class="{ 'bg-warning text-white' : val == today }"
                    v-for="(val, i) in rows.dates"
                    :key="i"
                  >{{ val }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(items, i) in rows.returns" :key="i">
                  <td class="text-uppercase">
                    <router-link :to="'/investor/' + items[0].user.uuid">{{ items[0].user.name }}</router-link>
                    <!--                     <a
                      target="_blank"
                      :href="'/investor/' + items[0].user.uuid"
                    >{{ items[0].user.name }}</a>-->
                  </td>
                  <td class="bg-info text-white text-right">{{ items[0].ob | currency }}</td>
                  <td
                    class="text-right"
                    v-for="(item, ix) in items"
                    :key="ix"
                    :class="{ 'bg-success text-white' : parseFloat(item.returns) > 1000, 'bg-light' : parseFloat(item.returns) < 1 }"
                  >
                    <!-- <span v-if="item.returns">{{ item.returns.payout_amount }}</span>
                    <span v-else>{{ item.alt.payout_amount }}</span>-->
                    <span>{{ item.returns | currency }}</span>
                  </td>
                </tr>
                <tr class="bg-light">
                  <th colspan="2">TOTAL</th>
                  <th v-for="(val, i) in rows.daysums" :key="i">{{ val | currency }}</th>
                </tr>
                <tr class="bg-success text-white">
                  <th colspan="2">PAYOUT</th>
                  <th v-for="(val, i) in rows.daySumPayout" :key="i">{{ val | currency }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  data() {
    return {
      searchForm: {
        year: "",
        month: ""
      },
      today: 0,
      dataloaded: false,
      rows: [],
      rowx: [],
      daysofMonth: 0,
      currentMonth: "",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    };
  },

  mounted() {
    var date = new Date();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    this.today = (date.getDate() < 10 ? "0" : "") + date.getDate();

    this.daysofMonth = parseInt(this.daysInMonth(month, year));

    this.init();

    const d = new Date();
    this.currentMonth = this.monthNames[d.getMonth()];

    this.searchForm.year = d.getFullYear();
    this.searchForm.month = this.currentMonth;
  },

  computed: {
    years() {
      let years = [];
      let y = 2019;
      for (let i = y; i < y + 3; i++) {
        years.push(i);
      }
      return years;
    }
  },

  methods: {
    init() {
      this.dataloaded = false;
      /* api.payout.payoutForecast(this.searchForm).then(res => {
        this.rows = res.data.data;
        this.dataloaded = true;
      }); */
      api.payout.payoutForecastProjection(this.searchForm).then(res => {
        this.rows = res.data.data;
        this.dataloaded = true;
      });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },

    submitSearch() {
      //
    }
  }
};
</script>

<style scoped>
table {
  overflow: hidden;
}

td,
th {
  padding: 10px;
  position: relative;
  outline: 0;
}

body:not(.nohover) tbody tr:hover {
  background-color: #ffa;
}

td:hover::after,
thead th:not(:empty):hover::after,
td:focus::after,
thead th:not(:empty):focus::after {
  content: "";
  height: 10000px;
  left: 0;
  position: absolute;
  top: -5000px;
  width: 100%;
  z-index: -1;
}

td:hover::after,
th:hover::after {
  background-color: #ffa;
}

td:focus::after,
th:focus::after {
  background-color: lightblue;
}

/* Focus stuff for mobile */
td:focus::before,
tbody th:focus::before {
  background-color: lightblue;
  content: "";
  height: 100%;
  top: 0;
  left: -5000px;
  position: absolute;
  width: 10000px;
  z-index: -1;
}
</style>